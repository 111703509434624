import "./App.css";
import Create from "./components/Create";
import { Route, Routes } from "react-router-dom";
import Read from "./components/Read";
import Edit from "./components/edit";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Read></Read>}></Route>
        <Route exact path="/create" element={<Create></Create>}></Route>
        <Route exact path="/edit" element={<Edit></Edit>}></Route>
      </Routes>
    </>
  );
}

export default App;
