import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Read() {
  const [apiData, setApiData] = useState([]);

  function getData() {
    axios
      .get("https://65713fce09586eff6642586b.mockapi.io/crud")
      .then((response) => {
        setApiData(response.data);
      });
  }

  function handleDelete(id) {
    axios
      .delete(`https://65713fce09586eff6642586b.mockapi.io/crud/${id}`)
      .then(() => {
        getData();
      })
      .catch((err) => {
        console.log("Error from Delete", err);
      });
  }

  function setDataToStorage(id, name, email, age) {
    localStorage.setItem("id", id);
    localStorage.setItem("name", name);
    localStorage.setItem("email", email);
    localStorage.setItem("age", age);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container mt-4">
      <Link to="/create" className="btn btn-primary mb-3">
        Create New Data
      </Link>

      <table className="table table-bordered table-striped table-hover">
        <thead className="thead-dark">
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Age</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {apiData.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.e_name}</td>
              <td>{item.e_email}</td>
              <td>{item.e_age}</td>
              <td>
                <Link to="/edit">
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      setDataToStorage(
                        item.id,
                        item.e_name,
                        item.e_email,
                        item.e_age
                      )
                    }
                  >
                    Edit
                  </button>
                </Link>
              </td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    if (window.confirm("Are you sure want to delete?")) {
                      handleDelete(item.id);
                    }
                  }}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Read;
